/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import { getResponsiveImage } from '@/componentWebs/ImageNew';
import debounce from 'lodash/debounce';
import cookie from 'js-cookie';

function Footer(props) {
  const { dataSite, menuFooter, languagesId, locale } = props;
  const [showLang, setShowLang] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = debounce(() => {
    setShowLang(!showLang);
  }, 200);

  // Close menu when clicking outside
  useEffect(
    () => {
      const handleClickOutside = event => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setShowLang(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    },
    [menuRef]
  );

  function chunkArray(array, size) {
    return Array.from({ length: Math.ceil(array.length / size) }, (v, i) =>
      array.slice(i * size, i * size + size)
    );
  }
  // console.log(menuFooter);
  const logo = dataSite?.logo?.[0]?.file || '';
  return (
    <footer className="footer_v2">
      <div className="wrapper">
        <div className="f-nav-box">
          <div className="f-nav-box-column copyright-box">
            <img src={getResponsiveImage(logo, [250, 250, 200])} alt="" className="lazyload" />
            <nav
              ref={menuRef}
              className={`lang-box ${(showLang && 'active') || ''}`}
              style={{ zIndex: 1000 }}
              translate="no"
            >
              <ul
                className="lang"
                onClick={event => {
                  event.stopPropagation();
                  toggleMenu();
                }}
              >
                {dataSite?.siteProfiles?.map(i => (
                  <li
                    key={i?.languagesId}
                    className={(languagesId === i?.languagesId && 'active') || ''}
                  >
                    <a
                      onClick={() => {
                        if (languagesId !== i?.languagesId) {
                          cookie.set('locale', i?.languages?.languagesCode);
                          cookie.set('languagesId', i?.languagesId);
                        }
                      }}
                      href={
                        (languagesId !== i?.languagesId && `/${i?.languages?.languagesCode}`) ||
                        undefined
                      }
                    >
                      <img
                        src={getResponsiveImage(i?.languages?.icon?.file, [16, 16, 16])}
                        width={16}
                        alt=""
                        className="lazyload"
                      />
                      {i?.languages?.languagesName}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className="f-nav-box-column f-nav-container">
            <nav className="f-nav">
              {menuFooter.map(item => {
                if (item?.children?.length <= 4) {
                  return (
                    <div className="f-nav-column f-nav-articles" key={item?.id}>
                      <h3>{item?.menusName}</h3>
                      <ul>
                        {item?.children?.map(i2 => (
                          <li key={i2?.id}>
                            <a href={(i2?.url && `/${locale}${i2?.url}`) || undefined}>
                              {i2?.menusName}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                }
                return (
                  <>
                    {chunkArray(item?.children, 4)?.map((chid, index) => (
                      <div className="f-nav-column f-nav-articles" key={`${item?.id}_${index}`}>
                        <h3 style={{ opacity: index === 0 ? 1 : 0 }}>{item?.menusName}</h3>
                        <ul>
                          {chid?.map(i2 => (
                            <li key={i2?.id}>
                              <a href={(i2?.url && `/${locale}${i2?.url}`) || undefined}>
                                {i2?.menusName}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </>
                );
              })}
            </nav>
          </div>
        </div>
        <p className="copyright" dir="ltr">
          © 2024{' '}
          <a href="https://vgasoft.vn" target="_blank" rel="noopener noreferrer">
            Vgasoft.vn
          </a>
          . All Rights Reserved. Developed by VGA SOFT Design
        </p>
      </div>
    </footer>
  );
}

export default Footer;
