/* eslint-disable no-use-before-define */
/* eslint-disable react/button-has-type */
import React, { useState, useRef, useEffect } from 'react';
import { getResponsiveImage } from '@/componentWebs/ImageNew';
import debounce from 'lodash/debounce';
import cookie from 'js-cookie';

function Header(props) {
  const { dataSite, menuHeader, languagesId, locale } = props;
  const [showLang, setShowLang] = useState(false);
  const [showmenu, setShowmenu] = useState(false);
  const menuRef = useRef(null);
  // console.log(locale);

  useEffect(
    () => {
      const handleClickOutside = event => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setShowLang(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    },
    [menuRef]
  );
  const handleClick = debounce(() => {
    setShowmenu(pre => !pre);
  }, 200);

  const toggleMenu = debounce(() => {
    setShowLang(!showLang);
  }, 200);
  // transform data
  const logo = dataSite?.logo?.[0]?.file || '';
  // console.log('datainfoArticle', menuHeader);

  return (
    <header className="header header_v2">
      <div className="page-width-inner g-row_inline">
        <div className="header__left g-col c3">
          <span
            className="menu-btn menu-btn_v2"
            onClick={event => {
              event.stopPropagation();
              handleClick();
            }}
          />
          <a name="logo" className="logo" href={`/${locale}`}>
            <img
              className="lazyload"
              src={getResponsiveImage(logo, [250, 200, 180])}
              alt={dataSite?.sitesName || ''}
            />
          </a>
        </div>
        <nav id="nav_top" className={`g-col c9 ${(showmenu && 'active') || ''}`}>
          <div className="nav-top-2 right">
            {menuHeader.map(item => (
              <a
                className="nav-top-2__item"
                href={(item?.url && `/${locale}${item?.url}`) || undefined}
                key={item?.id}
                style={{ display: !item?.displayChild && 'none' }}
              >
                {item.menusName}
              </a>
            ))}

            <nav
              ref={menuRef}
              className={`lang-box ${(showLang && 'active') || ''}`}
              style={{ zIndex: 1000 }}
              translate="no"
            >
              <ul
                className="lang top"
                onClick={event => {
                  event.stopPropagation();
                  toggleMenu();
                }}
              >
                {dataSite?.siteProfiles?.map(i => (
                  <li
                    key={i?.languagesId}
                    className={(languagesId === i?.languagesId && 'active') || ''}
                  >
                    <a
                      onClick={() => {
                        if (languagesId !== i?.languagesId) {
                          cookie.set('locale', i?.languages?.languagesCode);
                          cookie.set('languagesId', i?.languagesId);
                        }
                      }}
                      href={
                        (languagesId !== i?.languagesId && `/${i?.languages?.languagesCode}`) ||
                        undefined
                      }
                    >
                      <img
                        className="lazyload"
                        src={getResponsiveImage(i?.languages?.icon?.file, [16, 16, 16])}
                        width={16}
                        alt=""
                      />
                      {i?.languages?.languagesName}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </nav>
      </div>
    </header>
  );
}
export default Header;
