import NextHead from 'next/head';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const defaultOGURL = `${publicRuntimeConfig.APP_URL}`;
const defaultOGImage = `${publicRuntimeConfig.APP_URL}/web/images/favicon/favicon.ico`;
const defaultOGSiteName = `${publicRuntimeConfig.APP_NAME}`;
const defaultOGType = `${publicRuntimeConfig.APP_TYPE}`;
const origin = `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}`;

const Head = ({
  title,
  keywords,
  description,
  type = defaultOGType,
  ogImage = defaultOGImage,
  dataSite,
  iconDefault,
  locale,
  verificationGoogle = 'AusYkm5eXdc40GIYSKZWWiIBcN-EQqaJF-Bk8WIKGQk',
}) => {
  let url;
  if (typeof window !== 'undefined') {
    url = window.location.href;
  }
  const logo =
    (dataSite?.logo?.file &&
      (dataSite?.logo?.file?.indexOf('http') === -1
        ? `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
            dataSite?.logo?.file
          }`
        : dataSite?.logo?.file)) ||
    `${publicRuntimeConfig.APP_URL}/web/images/favicon/favicon.ico`;
  const icon =
    (dataSite?.icon?.file &&
      (dataSite?.icon?.file?.indexOf('http') === -1
        ? `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
            dataSite?.icon?.file
          }`
        : dataSite?.icon?.file)) ||
    `${publicRuntimeConfig.APP_URL}/web/images/favicon/favicon.ico`;
  const nameSite =
    dataSite?.siteProfiles?.find(i => i?.languages?.languagesCode === locale)?.siteProfilesName ||
    dataSite?.sitesName ||
    publicRuntimeConfig.APP_NAME;
  const ogImageNew = ogImage || logo;
  const iconNew = iconDefault || icon;
  const titleName = title || nameSite;
  const keywordsSeo = keywords || dataSite?.seoKeywords;
  const descriptionSeo = description || dataSite?.seoDescriptions || '';

  return (
    <NextHead>
      <title>{titleName}</title>
      <meta name="keywords" content={keywordsSeo} />
      <meta name="description" content={descriptionSeo} />
      {/* Google / Search Engine Tags  */}
      <meta itemProp="name" content={titleName} />
      <meta
        itemProp="image"
        content={ogImageNew?.indexOf('http') === -1 ? `${origin}${ogImageNew}` : ogImageNew}
      />

      {/* Facebook Meta Tags  */}
      <meta property="og:url" content={url || defaultOGURL} />
      <meta property="og:title" content={titleName} />
      <meta name="robots" content="index,follow,all" />
      <meta property="og:site_name" content={defaultOGSiteName} />
      <meta property="og:type" content={type || defaultOGType} />
      <meta property="og:image:alt" content={titleName} />
      <meta property="og:description" content={descriptionSeo} />
      <meta name="twitter:site" content={url || defaultOGURL} />
      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name="google-site-verification" content="AusYkm5eXdc40GIYSKZWWiIBcN-EQqaJF-Bk8WIKGQk" /> */}
      <meta name="google-site-verification" content={verificationGoogle} />
      <meta
        name="twitter:image"
        content={ogImageNew?.indexOf('http') === -1 ? `${origin}${ogImageNew}` : ogImageNew}
      />
      <meta
        property="og:image"
        content={ogImageNew?.indexOf('http') === -1 ? `${origin}${ogImageNew}` : ogImageNew}
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      />
      <meta charSet="UTF-8" />

      <link rel="canonical" href={url || defaultOGURL} />
      <link rel="icon" type="image/png" sizes="32x32" href={iconNew} />
      <link rel="icon" type="image/png" sizes="96x96" href={iconNew} />
      <link rel="icon" type="image/png" sizes="16x16" href={iconNew} />
    </NextHead>
  );
};

export default Head;
