/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-danger */
/* eslint-disable global-require */
import React from 'react';
import cookiee from 'cookie';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import { getComponentDisplayName } from '../utils/helpers';
import { /* defineMessages, */ injectIntl } from 'react-intl';
import Head from '@/componentWebs/headLayout';
import { languages } from '@/utils/utils';

const Header = dynamic(() => import('@/componentWebs/downloadvideo/Header'), {
  ssr: true,
  loading: () => null,
});
const Footer = dynamic(() => import('@/componentWebs/downloadvideo/Footer'), {
  ssr: true,
  loading: () => null,
});
const { publicRuntimeConfig } = getConfig();
const fontCss = `${publicRuntimeConfig.DOMAIN_STATIC}/web/fonts/fontawesome/css/all.css`;
const inline = `${publicRuntimeConfig.DOMAIN_STATIC}/downloadvideo/css/inline.css`;
const style = `${publicRuntimeConfig.DOMAIN_STATIC}/downloadvideo/css/style.css`;
const ckeditor = `${publicRuntimeConfig.DOMAIN_STATIC}/downloadvideo/css/ckeditor.css`;
// lazy load
const lazyLoadimg = `${publicRuntimeConfig.DOMAIN_STATIC}/web/js/lazysizes.min.js`;
// const carousel = `${publicRuntimeConfig.DOMAIN_STATIC}/web/js/owl.carousel.js`;

export default ComposedComponent => {
  class WebLayout extends React.Component {
    static displayName = `WebLayout(${getComponentDisplayName(ComposedComponent)})`;

    static async getInitialProps(context) {
      const { req, asPath, pathname, query, store, dispatch } = context;
      let cookies;
      let host = '';
      let dataSite = {};
      if (req) {
        // console.log('headers', req.headers);
        // console.log('SITE_NAME', publicRuntimeConfig.SITE_NAME);
        // eslint-disable-next-line prefer-destructuring
        cookies = req.cookies;
        host =
          req.headers.host === 'localhost:8834' ? publicRuntimeConfig.SITE_NAME : req.headers.host;
      } else {
        const documentCookie = document.cookie;
        cookies = cookiee.parse(documentCookie);
        host =
          window.location.host === 'localhost:8834'
            ? publicRuntimeConfig.SITE_NAME
            : window.location.host;
      }
      if (host.indexOf('www') === 0) {
        host = host.slice(4);
      }
      await dispatch({
        type: 'webs/fetchSiteUrl',
        payload: { url: host },
        callback: result => {
          dataSite = result;
        },
      });

      const SITEID_WEB = dataSite?.id;
      const languagesDefault =
        dataSite?.siteProfiles?.find(i => i?.isDefault === 1)?.languagesId || '1';
      const locale =
        (query?.lang && languages?.find(i => i?.languagesCode === query?.lang)?.languagesCode) ||
        cookies?.locale ||
        languages?.find(i => i?.id === languagesDefault)?.languagesCode ||
        'vi';
      const languagesId =
        dataSite?.siteProfiles?.find(i => i?.languages?.languagesCode === locale)?.languagesId ||
        '1';

      if (SITEID_WEB) {
        const queryMenus = await {
          filter: {
            status: '1',
            menuPositionsId: `${publicRuntimeConfig.MENU__POSITIONID_TOP},${
              publicRuntimeConfig.MENU__POSITIONID_BOTTOM
            }`,
            languagesId,
            sitesId: SITEID_WEB,
          },
          sort: ['orderBy', 'ASC'],
        };
        await dispatch({
          type: 'webs/fetchMenus',
          payload: queryMenus,
        });
      }
      const {
        webs: { dataMenu },
      } = await store.getState();

      const menus = dataMenu?.list || [];
      const menuHeader =
        (menus?.length > 0 && menus.filter(item => Number(item.menuPositionsId) === 4)) || [];
      const menuFooter =
        (menus?.length > 0 && menus.filter(item => Number(item.menuPositionsId) === 3)) || [];
      // console.log('dataSite?.siteProfiles', dataSite?.siteProfiles);
      // console.log(res);
      return {
        ...(ComposedComponent.getInitialProps
          ? await ComposedComponent.getInitialProps(context)
          : {}),
        query,
        cookies,
        asPath,
        pathname,
        dataSite,
        locale,
        languagesId,
        menuHeader,
        menuFooter,
        host,
      };
    }

    render() {
      return (
        <React.Fragment>
          <Head>
            {/* <link rel="stylesheet" href={fontCss} type="text/css" media="all" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" /> */}
            <link
              rel="preload"
              href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap"
              as="style"
              onLoad="this.rel='stylesheet'"
            />
            <link rel="stylesheet" href={inline} type="text/css" media="all" />
            <link rel="stylesheet" href={style} type="text/css" media="all" />
            <link rel="stylesheet" href={ckeditor} type="text/css" media="all" />
            <script async src={lazyLoadimg} />
          </Head>
          <Header {...this.props} />
          <ComposedComponent {...this.props} />
          <Footer {...this.props} />
        </React.Fragment>
      );
    }
  }

  return injectIntl(WebLayout);
};
